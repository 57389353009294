<template>
  <div>
    <div class="d-flex align-center mb-4 mb-md-3">
      <icon-button v-if="media.isMobile" class="mr-2" :to="backLink">
        <v-icon>mdi-arrow-left</v-icon>
      </icon-button>
      <h1 class="text-h5 text-md-h4 font-weight-bold">{{ $t('invoice.choose_card') }}</h1>
    </div>

    <PaymentStatusModal
      v-model="isShowModal"
      :status-pay="statusPay"
      :loading="isButtonLoading"
      :prev-page="prevPage"
    />

    <cards-info class="mb-6 mb-md-9" />

    <cards-list v-if="isLoading">
      <cards-list-grid v-for="(_, index) in 2" :key="index" :class="{ 'mb-4': index !== 1 }">
        <cards-stub />
      </cards-list-grid>
    </cards-list>

    <cards-list v-else>
      <cards-list-grid v-for="card in cards" :key="card.cardId" class="mb-4">
        <cards-base-item
          :number="card.last4"
          :name="card.brand"
          :is-selected="isDefaultCardSelected(card)"
          :show-default-title="card.isDefault"
          :show-mark="isDefaultCardSelected(card)"
          :is-european="card.isEuropean"
          @click="selectCard(card)"
        />
      </cards-list-grid>

      <cards-list-grid>
        <cards-add @click="addCard" />
      </cards-list-grid>
    </cards-list>

    <div class="d-flex mt-4 justify-end">
      <v-btn v-if="!media.isMobile" large color="secondary" class="primary--text elevation-0 mr-6" :to="backLink">
        {{ $t('button.back') }}
      </v-btn>
      <v-btn
        large
        color="primary"
        class="elevation-0"
        :disabled="isLoadingOrCardNotSelected"
        :loading="isButtonLoading"
        :block="media.isMobile"
        @click="pay"
      >
        {{ $t('button.pay') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
// Services
import payments from '@/services/payments';

// Constants
import { INVOICES } from '@/constants/routes';
import { PREPARE_PAYMENT, PAYMENT } from '@/constants/analyticsActions';
import { UNPAID } from '@/constants/payInvoiceStatus';

// Components
import CardsList from '@/components/Cards/List.vue';
import CardsBaseItem from '@/components/Cards/BaseListItem.vue';
import CardsAdd from '@/components/Cards/Add.vue';
import CardsStub from '@/components/Cards/Stub.vue';
import CardsListGrid from '@/components/Cards/ListGrid.vue';
import IconButton from '@/components/IconButton.vue';
import CardsInfo from '@/components/Cards/Info.vue';
import PaymentStatusModal from '@/components/Invoices/PaymentStatusModal.vue';

// Services
import analyticsService from '@/services/analytics';

const FAIL = 'fail';

export default {
  name: 'InvoicePayment',

  components: {
    CardsList,
    CardsBaseItem,
    CardsAdd,
    CardsStub,
    CardsListGrid,
    IconButton,
    CardsInfo,
    PaymentStatusModal,
  },

  inject: ['media'],

  props: {
    id: { type: Number, required: true },
    prevPage: { type: Number, default: 1 },
  },

  data() {
    return {
      isLoading: false,
      cards: [],
      selectedCard: {},
      isShowModal: false,
      isButtonLoading: false,
      statusPay: UNPAID,
    };
  },

  computed: {
    backLink() {
      return { name: INVOICES, query: { page: this.prevPage || 1 } };
    },

    isCardSelected() {
      return !!this.selectedCard?.cardId;
    },

    isLoadingOrCardNotSelected() {
      return !this.isCardSelected || this.isLoading;
    },

    defaultCard() {
      return this.cards.find(card => card.isDefault);
    },
  },

  mounted() {
    this.isLoading = true;

    analyticsService.track(PREPARE_PAYMENT);
    payments
      .getMethods()
      .then(cards => {
        this.cards = cards.results;
        this.selectCard(this.defaultCard);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    async addCard() {
      const URI = await payments.getSessionURI();

      document.location.assign(URI);
    },

    selectCard(card) {
      this.selectedCard = card;
    },

    isDefaultCardSelected(card) {
      return this.selectedCard?.cardId === card?.cardId;
    },

    async pay() {
      this.isButtonLoading = true;
      this.openInfoModal();

      payments
        .createPayment({
          invoice: this.id,
          paymentMethod: this.selectedCard.cardId,
        })
        .then(({ status }) => {
          analyticsService.track(PAYMENT, analyticsService.createPaymentPayload(status));
          this.setStatusInfoModal(status);
        })
        .catch(() => {
          analyticsService.track(PAYMENT, analyticsService.createPaymentPayload(FAIL));
          this.setStatusInfoModal(UNPAID);
        })
        .finally(() => {
          this.isButtonLoading = false;
        });
    },

    openInfoModal() {
      this.isShowModal = true;
    },

    setStatusInfoModal(status) {
      this.statusPay = status;
    },
  },
};
</script>
