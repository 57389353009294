<template>
  <base-modal v-model="modelWrapper" hidden-close-button persistent content-class="payment-status-modal">
    <template #title>
      <h5>
        {{ $t('invoice.payment_process')
        }}<span v-if="!loading"
          >: <span class="payment-status-modal__status-pay" :class="statusClass">{{ $t(titleModal) }}</span></span
        >
      </h5>
    </template>
    <div v-if="loading" class="payment-status-modal__loader">
      <v-progress-circular color="blue50" size="40" width="2" indeterminate />
    </div>
    <div v-else>
      <p class="payment-status-modal__text">
        {{ $t(textModal) }}
      </p>
      <v-btn block large elevation="0" color="primary" @click="clickButton">{{ $t(textButtonModal) }}</v-btn>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';

import { PENDING, PAID, UNPAID } from '@/constants/payInvoiceStatus';
import { INVOICES } from '@/constants/routes';

const configStatusPay = {
  [PENDING]: {
    text: 'invoice.pay_text_status_pending',
    title: 'invoice.pay_title_status_pending',
    textButton: 'button.back_invoice',
    class: 'payment-status-modal__status-pay--pending',
  },
  [PAID]: {
    text: 'invoice.pay_text_status_paid',
    title: 'invoice.pay_title_status_paid',
    textButton: 'button.back_invoice',
    class: 'payment-status-modal__status-pay--success',
  },
  [UNPAID]: {
    text: 'invoice.pay_text_status_unpaid',
    title: 'invoice.pay_title_status_unpaid',
    textButton: 'button.try_again',
    class: 'payment-status-modal__status-pay--error',
  },
};

export default {
  name: 'PaymentStatusModal',
  components: { BaseModal },
  model: {
    prop: 'isOpen',
    event: 'update:isOpen',
  },
  inject: ['media'],
  props: {
    isOpen: { type: Boolean, default: false },
    statusPay: { type: String, required: true },
    loading: { type: Boolean, default: false },
    prevPage: { type: Number, default: 1 },
  },

  computed: {
    modelWrapper: {
      get() {
        return this.isOpen;
      },

      set(value) {
        this.$emit('update:isOpen', value);
      },
    },

    statusClass() {
      return configStatusPay[this.statusPay].class;
    },

    textModal() {
      return configStatusPay[this.statusPay].text;
    },

    titleModal() {
      return configStatusPay[this.statusPay].title;
    },

    textButtonModal() {
      return configStatusPay[this.statusPay].textButton;
    },
  },

  methods: {
    clickButton() {
      if (this.statusPay === UNPAID) {
        this.closeModal();
      } else {
        this.goToInvoices();
      }
    },
    closeModal() {
      this.modelWrapper = false;
    },
    goToInvoices() {
      this.$router.push({ name: INVOICES, query: { page: this.prevPage || 1 } });
    },
  },
};
</script>

<style lang="scss">
.payment-status-modal {
  &__text {
    margin-bottom: 32px !important;
  }

  &__status-pay {
    &--success {
      color: $--green-color-0;
    }
    &--error {
      color: $--red-color-0;
    }
    &--pending {
      color: $--blue-color-50;
    }
  }
  &__loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
